<template>
	<div class="main-content">
		<ktv-breadcrumb
			title="User List"
			page="Users"
			folder="System"
		/>
		<ktv-table
			:columns="columns"
			:filter="true"
			:is-loading="isLoading"
			:rows="rows"
			:total-rows="totalRecords"
			:mode="modeTable"
			:search-custom-enabled="true"
			search-placeholder="Search by Real Name"
			@on-column-filter="onColumnFilter"
			@on-page-change="onPageChange"
			@on-per-page-change="onPerPageChange"
			@on-search="onSearchFilter"
			@on-sort-change="onSortChange"
		>
			<template #actionleft>
				<ktv-button
					text="Add"
					icon="add"
					color="green-light"
					@click="openForm('Add')"
				/>
			</template>
			<template #rows="{ props }">
				<span v-if="props.column.field == 'button'">
					<b-dropdown
						size="xs"
						variant="link"
						toggle-class="text-decoration-none"
						no-caret
					>
						<template #button-content>
							<span class="btndropdownaction">
								<img :src="icondropdown">
							</span>
						</template>
						<b-dropdown-item class="dropdownitem-action" @click="openForm('View', props.row.UserId)">
							<img :src="iconview" class="imgicon"> View
						</b-dropdown-item>
						<b-dropdown-item class="dropdownitem-action" @click="openForm('Update', props.row.UserId)">
							<img :src="iconedit" class="imgicon"> Update
						</b-dropdown-item>
						<b-dropdown-item class="dropdownitem-action" @click="deleteData(props.row.UserId)">
							<img :src="icondelete" class="imgicon"> Delete
						</b-dropdown-item>
					</b-dropdown>
				</span>
				<span v-else-if="props.column.field == 'StatusCd'">
					<b-badge
						v-if="props.row.StatusCd == 'active'"
						href="#"
						variant="primary  m-2"
					>{{ props.row.StatusCd }}</b-badge>
					<b-badge
						v-else-if="props.row.StatusCd == 'inactive'"
						href="#"
						variant="danger  m-2"
					>{{ props.row.StatusCd }}</b-badge>
				</span>
			</template>
		</ktv-table>
	</div>
</template>

<script>
	import {  KtvButton, KtvTable } from "@/components"
	import { mapActions } from 'vuex'

	export default {
		metaInfo: {
			title: "Users",
		},
		components: {  KtvButton, KtvTable },
		data() {
			return {
				/* eslint-disable global-require */
				iconedit: require("@/assets/images/icon/iconedit.png"),
				icondelete: require("@/assets/images/icon/icondelete.png"),
				iconview: require("@/assets/images/icon/iconview.png"),
				icondropdown: require("@/assets/images/icon/icondropdown.png"),
				/* eslint-disable global-require */
				isLoading: false,
				modeTable: "remote",
				columns: [
					{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
						sortable: false
					},
					{
						label: "Real Name",
						field: "UserRealName",
						thClass: "text-left",
					},
					{
						label: "User Name",
						field: "UserName",
						thClass: "text-left",
					},
					{
						label: "User Role",
						field: "RoleName",
						thClass: "text-left",
					},
					{
						label: "User Group",
						field: "GroupName",
						thClass: "text-left",
					},
					{
						label: "Active",
						field: "StatusCd",
						thClass: "text-left",
					},
				],
				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
						UserRealName: "",
					},
					sort: [
						{
							field: "UserRealName",
							type: "ASC",
						},
					],
					currentPage: 1,
					rowPerpage: 10,
				},
			}
		},
		computed: {
			groupDef() {
				let data = this.groups.filter((group) => this.form.Groups.includes(group.GroupId))
				let returnData = []
				data.forEach((val) => {
					returnData.push({
						value: val.GroupId,
						text: val.GroupName,
					})
				})
				return returnData
			},
		},
		mounted() {
			this.loadItems()
		},
		methods: {
			...mapActions("USERS", ["pushData","actDeleteData"]),
			openForm(opsi, item = null) {
				let data = {
					opsiDisplay: opsi,
					UserId: item,
				}
				this.pushData(data);
				this.$router.push('users/usersform')
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.loadItems()
			},
			onSearchFilter(event) {
				this.updateParams({
					columnFilters: { UserRealName: event },
				})
				this.loadItems()
			},
			// load items is what brings back the rows from server
			loadItems() {
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.user.list)
					.then((response) => {
						this.isLoading = false
						if (response.success) {
							this.rows = response.results.data
							this.totalRecords = response.results.total
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.isLoading = false
						this.$swal("Error!", error.response, "error")
					})
			},
			deleteData(UserID) {
				let payload = {
					userId: UserID,
				}
				this.$swal({
					title: 'Are you sure?',
					text: "Data will be deleted!",
					type: 'question',
					showCancelButton: true,
					confirmButtonText: 'Delete',
					showLoaderOnConfirm: true,
					preConfirm: () => {
						return this.actDeleteData(payload).then((res) => {
							return res;
						}).catch((error) => {
							this.$swal.hideLoading();
							return error;
						});
					},
					allowOutsideClick: () => !this.$swal.isLoading()
				}).then((res) => {
					if (res.value.success) {
						this.$swal('Success!', 'Data has been deleted.', 'success')
							.then(() => { this.loadItems() })
					} else {
						this.$swal('Error!', 'Data failed to be deleted.', 'error')
					}
				})
			},
		},
	}
</script>
<style>
	.dropdownitem-action:hover a, .dropdownitem-action:focus a{
		background-color: #CAEAFF !important;
	}
</style>
